export const SCREEN_SM = 640
export const SCREEN_MD = 768
export const SCREEN_LG = 1024
export const SCREEN_XL = 1280
export const SCREEN_2XL = 1536

export const TOP_BAR_HEIGHT = 52
export const SHOP_TOP_BAR_HEIGHT = 52

export const SLIDER_SWIPE_THRESHOLD = 50

export const PROTOCOL_DOMAIN = '.protocol.space'
